import {Link} from 'react-router-dom'
import IntroImage from '../assets/intro.png'
import {IconButton} from "./IconButton"
import {StorageContext} from "./Storage"

import {faCaretRight} from '@fortawesome/free-solid-svg-icons'
import {useContext} from "react"

export function LandingPage() {
    const storage = useContext(StorageContext)

    return <div id="landingPage">
        <div id="landingArea">
            <div id="introImageArea">
                <img src={IntroImage} id="introImage"/>
            </div>
            <div id="landingInterface">
                <div id="landingText">
                    <h1>Hi, I'm Kenny Kim!</h1>
                    <div id="themeList">
                        <span className="explo">EXPLORER</span>
                        <span> | </span>
                        <span className="collab">COLLABORATOR</span>
                        <span> | </span>
                        <span className="prob">PROBLEM SOLVER</span>
                    </div>
                </div>
                <div id="landingOptions">
                    <Link to='/'>
                        <div id="landingIntroOption" onClick={()=>{
                            storage.summaryMode=true
                            window.sessionStorage.setItem("firstTime", "false");
                            }}>
                            <h3>Introduction</h3>
                            <IconButton icon={faCaretRight} keyListener="Space"/>
                        </div>
                    </Link>
                    <Link to="/">
                        <div id="landingMainOption" onClick={()=>{window.sessionStorage.setItem("firstTime", "false");}}>
                            Skip to Main Page</div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}