[
    {
        "quote": "The man's alright",
        "author": "Best Friend"
    },
    {
        "quote": "Kenny somtimes makes things",
        "author": "Kyle"
    },
    {
        "quote": "He spent a lot of time on this website",
        "author": "Dude"
    },
    {
        "quote": "That guy just be like that",
        "author": "Person"
    },
    {
        "quote": "I don't know what he's doing",
        "author": "Anon"
    }
]