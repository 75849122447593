"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _invariant = require("invariant");

var _invariant2 = _interopRequireDefault(_invariant);

var _properties = require("./properties");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var defaultUnits = {
  length: "px",
  angle: "deg"
};

var isArray = function isArray(value) {
  return Array.isArray(value);
};

var hasProp = function hasProp(o, p) {
  return o.hasOwnProperty(p);
};

var isValidProp = function isValidProp(value) {
  return typeof value === "number" || typeof value === "string" || isArray(value);
};

var createUnit = function createUnit(units, unit) {
  return unit === _properties.UnitTypes.NONE ? "" : units[unit];
};

var createValue = function createValue(value, unit) {
  return typeof value === "number" ? "" + value + unit : value;
};

var normalizeValue = function normalizeValue(prop, value, units) {
  if (hasProp(prop, "units")) {
    if (typeof value === "string") {
      return value;
    }

    (0, _invariant2.default)(isArray(value), "Should be " + prop.name + " is a array");
    (0, _invariant2.default)(value.length === prop.units.length, "Should be " + prop.name + " is " + prop.units.length + " values.");

    return prop.units.map(function (unit, i) {
      return createValue(value[i], createUnit(units, unit));
    }).join(", ");
  }

  return createValue(value, createUnit(units, prop.unit));
};

var createCSSTransformBuilder = function createCSSTransformBuilder() {
  var units = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultUnits;

  return function (styles) {
    return _properties.transformProperties.map(function (prop) {
      return !hasProp(styles, prop.name) || !isValidProp(styles[prop.name]) ? null : prop.name + "(" + normalizeValue(prop, styles[prop.name], units) + ")";
    }).filter(function (value) {
      return value != null;
    }).join(" ");
  };
};

exports.default = createCSSTransformBuilder;